@import "../../styles/variables";

.selection {
	z-index: 10;

	.selection-header {
		position: relative;
		margin-top: 20px;

		.title {
			display: flex;
			font-size: 1.2rem;
			color: white;
			background-color: $dagobaBlue;
			text-align: center;
			padding: 7px 0px 7px 10px;
			border-radius: 3px;
		}

		.empty-button {
			position: absolute;
			top: 16px;
			right: 3px;
			color: white;
			cursor: pointer;
		}

	}

	.empty-selection {
		padding: 50px 10px;
		text-align: center;
		font-size: var(--fs-md);
		line-height: var(--fs-lg);
		font-weight: 300;
		color: $dagobaBlue;
		opacity: 0.2;
	}

	.sel-product {
		position: relative;

		&.assigned {
			opacity: 0.3;
		}

		.product-tbn {
			background-color: white;
			border: 1px solid #D0D0D0;
			border-radius: 4px;
			padding: 3px;
			cursor: pointer;

			.first-img {
				text-align: center
			}

			.other-img {
				display: flex;
				justify-content: center;
				margin-top: 2px;
			}
		}

		.otherSelectedVersions {
			display: flex;
			flex-direction: column;
			position: absolute;
			top: 15px;
			right: 5px;
			width: 10px;
			z-index: 1;

			.gender {
				color: $dagobaBlue;
				margin-bottom: 2px;
			}
		}
	}
}


