@import "../../styles/variables";

.speedy-signup {

	@include website-design;

	h2 {
		font-size: var(--fs-md);
		line-height: var(--fs-xl);
		color: $dagobaBlue;
	}
}