@import "../../styles/variables";

.app-bar {
	@include website-design;

	width: 100%;
	background-color: $dagobaBlue !important;
	font-size: var(--fs-md);

	//&.development {
	//	background-color: #c194dc !important;
	//}

	.menu-button {
		display: flex;
		align-content: center;
		color: white;
		font-size: var(--fs-md);
		line-height: 1rem;
		min-width: 0 !important;
		//svg path {
		//	fill: white;
		//}
		.tshirt {
			display: flex;
			margin-left: clamp(-1rem, -2vw, 0px);
			align-items: center;
			.icon {
				path {
					fill: white;
				}
			}
		}
	}

	button {
		svg {
			font-size: var(--fs-lg);
		}
	}

	.cta {
		padding: clamp(0.3rem, 1.5vw, 1rem);
		background-color: green;
		color: white;
		font-size: var(--fs-lg);
		border-radius: 5px;
		cursor: pointer;
	}
}

@media print {
	.app-bar {
		display: none !important;
	}
}

