.logo-grid {
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.logo-row {
	display: flex;
	gap: 1rem;
}

.logo-card {
	/* We still use perspective here to allow 3D flipping inside */
	display: flex;
	justify-content: center;
	align-items: center;
	perspective: 1000px;
}

/* This container actually flips */
.logo-flip-container {
	width: 100%;
	height: 100%;
	transform-style: preserve-3d;
	transition: transform 0.6s ease-in-out;
}

/* 0°: not flipping */
.flip-phase-0 {
	transform: rotateY(0deg);
}

/* 90°: half flipped */
.flip-phase-1 {
	transform: rotateY(90deg);
}

/* 180°: fully flipped */
.flip-phase-2 {
	transform: rotateY(180deg);
}

.logo-image {
	width: 100%;
	height: 100%;
	backface-visibility: hidden; // ensures backside is hidden when flipped
}
