

.utils-upload-files {
	display: flex;
	flex-wrap: wrap;
	margin-top: 15px;
	align-items: center;

	.upload-button {
		margin-right: 5px;
	}
}

.utils-upload-file-names {

	display: flex;
	flex-direction: column;
	font-size: 0.9em;

	.current {
		color: #2fad3a;
	}

	.selected {
		color: #0080C0;

		button {
			padding: 0;
		}
	}
}

