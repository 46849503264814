@import "../../styles/variables";

.logo-form {
	width: 100%;
	position: relative;
	padding: 10px;
	background-color: white;
	border: 1px solid #D0D0D0;
	border-radius: 5px;

	.name {
		font-size: 2em;
		line-height: 1.2em;
		margin-bottom: 20px;

		.admin-managed {
			font-size: 0.6em;
			color: #8f8f8f;
		}
	}

	.name-title {
		font-size: 0.7em;
		color: #A0A0A0;
	}

	.open-file-explanation {
		display: flex;
		justify-content: left;
		align-items: center;
		color: #8d06f1;
		padding-left: 16px;
	}

	.logo-edit {

		min-height: 400px;

		.image-wrapper {
			position: relative;
			text-align: center;
			padding: 15px;

			.image-stats {
				font-size: 1em;
				margin-bottom: 10px;

				.valid {
					margin-top: 5px;
					font-size: 0.9em;
					color: green;
					display: flex;
				}
				.error {
					margin-top: 5px;
					font-size: 0.9em;
					color: red;
					display: flex;
				}
			}

			.dropzone  {
				background: linear-gradient(to bottom right, #F0F0F0, #202020);
				button {
					cursor: pointer;
				}

				&.dark-bg {
					background: unset;
					background-color: #202020;

					button svg {
						color: #E0E0E0;
					}
				}

				&.light-bg {
					background: unset;
					background-color: #F0F0F0;

					button svg {
						color: black;
					}
				}

			}


			.load-logo {
				width: 100%;
				min-height: 400px;
				font-size: 1.5em;
			}

			.image-item {
				border: 1px solid #A0A0A0;
				width: 100%;
				text-align: center;
				position: relative;

				img {
					max-height: 400px;
					max-width: 100%;
				}

				.image-change {
					position: absolute;
					top: -5px;
					left: -5px;
				}
				.image-help {
					position: absolute;
					top: -5px;
					left: calc(50% - 22px);
				}
				.image-delete {
					position: absolute;
					top: -5px;
					right: -5px;
				}

			}
			.errors {
				//position: absolute;
				//width: 80%;
				//top: 10%;
				//margin-top: 10px;
				//text-align: center;
				//color: red;
				//left: 50%;
				//margin-left: -40%;
				//font-size: 30px;

				padding: 10px;
				text-align: center;
				color: red;
				font-size: 0.85em;

			}

		}

		.logo-versions {
			text-align: left;
			//min-width: 400px;
			padding: 10px;
			font-size: 1rem;

			.embroidery-files {
				margin-top: 10px;
				display: flex;
			}

			.logo-techniques {
				.MuiFormLabel-root {
					font-size: 1.5em;
					font-weight: bold;
				}
				.MuiRadioGroup-root .MuiFormControlLabel-label {
					font-size: 1.1em;
					font-weight: bold;
				}
				.techique-description {
					margin-bottom: 10px;
					font-size: 0.9em;

					.paragraphe {
						margin-bottom: 10px;
						padding-left: 20px;
					}
				}
			}

			.logo-versions-header {
				padding: 10px;
				text-align: center;
				font-weight: bold;
				color: $dagobaBlue;

				.areas {
					font-weight: 100;
					font-size: 14px;
				}
			}

			.logo-table {

				th, td {
					padding: 6px;
					font-size: var(--fs-xs);
				}
			}
		}

		.logo-select-bg {
			text-align: center;
		}

		.logo-previews {
			margin-top: 20px;
			text-align: center;

			.preview-article {
				position: relative;

				.preview-logo-title {
					font-size: 1.1em;
					font-weight: bold;
				}

				img {
					width: 80%;
					height: auto;
				}

				.logo-overlay {
					position: absolute;
					top: 25%;
					//left: 16px; // because of the 8px left padding of the container
					width: 100%;
					img {
						width: 20%;
						height: auto;
					}
				}
			}
		}

		.logo-usage {
			display: flex;
			flex-direction: column;
			align-items: center;

			.usage-header {
				padding: 10px;
				text-align: center;
				font-weight: bold;
				color: $dagobaBlue;
			}

			.usage {
				text-align: left;
				font-size: 0.85rem;
				h3 {
					font-size: 1rem;
					font-weight: bold;
					line-height: 1rem;
					margin: 0px;
				}
				h4 {
					color: #202060;
					font-size: 0.9rem;
					line-height: 0.9rem;
					margin: 0 0 0 20px;
				}
				h5 {
					color: #602020;
					font-size: 0.85rem;
					line-height: 0.85rem;
					margin: 0 0 0 40px;
				}
				h6 {
					color: #206020;
					font-size: 0.8rem;
					line-height: 0.8rem;
					margin: 0 0 0 60px;
				}
			}
		}

	}
}


