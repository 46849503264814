@import "../../../styles/variables.scss";

$hover_opacity: 0.8;

.logs-table {

	.lydia {
		background-color: #cbffcb;
		color: #000;

		&:hover {
			background-color: #cbffcb !important;
			opacity: $hover_opacity;
		}
	}

	.lorenzo {
		background-color: #fcffd1;
		color: #000;
		&:hover {
			background-color: #fcffd1 !important;
			opacity: $hover_opacity;
		}
	}

	.dagobot {
		background-color: #D0D0D0;
		color: #000;
		&:hover {
			background-color: #D0D0D0 !important;
			opacity: $hover_opacity;
		}
	}

	.franz {
		background-color: #e0dcff;
		color: #000;
		&:hover {
			background-color: #e0dcff !important;
			opacity: $hover_opacity;
		}
	}

}

