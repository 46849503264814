@import "../../styles/variables";

.markdown-documentation {
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 100%;

	.toc {
		flex: 0 0 30%;
		position: sticky;
		top: 0;
		height: 100vh;
		overflow-y: auto;
		background-color: #f9f9f9;
		border-right: 1px solid #e0e0e0;
		padding: clamp(0.25rem, 1.2vw, 2rem);
		will-change: transform;

		.toc-item {
			margin: 4px 0;

			&.toc-level-1 {
				color: $dagobaBlue;
				font-weight: bold;
			}
			&.toc-level-2 {
				color: green;
				margin-left: clamp(0.5rem, 1vw, 2rem);
			}
			&.toc-level-3 {
				color: darkred;
				margin-left: clamp(1rem, 1vw, 3rem);
			}
			&.toc-level-4 {
				color: #8227ba;
				margin-left: clamp(1.5rem, 1.5vw, 4rem);
			}

			.toc-header {
				display: flex;
				align-items: flex-start;

				.toc-link {
					font-size: var(--font-size-sm);
					font-weight: 400;
					cursor: pointer;
					text-decoration: none;
					&:hover {
						text-decoration: underline;
					}
					&.active {
						font-weight: bold;
						will-change: transform;
					}
				}

				.toc-icon {
					display: flex;
					cursor: pointer;
					font-size: var(--font-size-sm);
					font-weight: 500;
					margin-right: clamp(0.2rem, 1.5vw, 0.4rem);
					width: clamp(0.3rem, 1.5vw, 0.6rem);
				}
			}
		}
	}

	.content {
		flex: 1;
		padding: clamp(1rem, 2.5vw, 2rem);
		overflow-y: auto;

		h1, h2, h3, h4 {
			//scroll-margin-top: clamp(1rem, 5vw, 4rem);
			scroll-margin-top: 1rem;
		}

		p {
			margin: clamp(0.5rem, 1.5vw, 1rem) 0;
		}

		// style for tables: 1px solid #e0e0e0
		table {
			width: 100%;
			border-collapse: collapse;
			border-spacing: 0;
			border: 1px solid #e0e0e0;
			margin: clamp(0.5rem, 1.5vw, 1rem) 0;

			th, td {
				font-size: var(--font-size-sm);
				border: 1px solid #e0e0e0;
				padding: clamp(0.25rem, 1vw, 0.5rem);
			}
		}

		.private-placeholder {
			padding: clamp(1rem, 2.5vw, 2rem);
			border: 1px solid;
			border-radius: 3px;
			color: hsl(144, 72%, 21%);
			background-color: hsl(144, 72%, 95%);
			border-color: hsl(144, 72%, 87%);
		}

		.icon {
			display: inline-block;
			svg {
				width: clamp(1.5rem, 3vw, 5rem);
				height: clamp(1.5rem, 3vw, 5rem);
			}
		}

	}
}

/* Small Devices (Phones - Landscape) */
@media (max-width: 450px) {

	.markdown-documentation {

		.toc {
			flex: 0 0 40%;
			padding: 5px;

			.toc-item {
				margin: 4px 0;

				&.toc-level-2 {
					margin-left: 0;
				}
				&.toc-level-3 {
					margin-left: 0;
				}
				&.toc-level-4 {
					margin-left: 0;
				}

				.toc-header {
					.toc-icon {
						margin-right: 4px;
						width: 6px;
					}

				}
			}
		}

		.content {
			flex: 1;
			padding: clamp(1rem, 2.5vw, 2rem);
			overflow-y: auto;

			h1, h2, h3, h4 {
				//scroll-margin-top: clamp(1rem, 5vw, 4rem);
				scroll-margin-top: 6px;
			}

			p {
				margin: 6px 0;
			}

			table {
				margin: 6px 0;

				th, td {
					font-size: 0.8rem;
					border: 1px solid #e0e0e0;
					padding: 2px;
				}
			}

			.icon {
				display: inline-block;
				svg {
					width: 1.5rem;
					height: 1.5rem;
				}
			}

		}
	}
}
