@font-face {
	font-family: 'Allstar';
	src: local('Allstar-Regular'), url(../fonts/Allstar-Regular.ttf) format('truetype');
}

@font-face {
	font-family: 'Amsterdam Graffiti';
	src: local('Amsterdam Graffiti'), url(../fonts/Amsterdam.ttf) format('truetype');
}

@font-face {
	font-family: 'Arial Black';
	src: local('Arial Black'), url(../fonts/Arial-Black.ttf) format('truetype');
}

@font-face {
	font-family: 'Arial Bold';
	src: local('Arial Bold'), url(../fonts/Arial-Bold.ttf) format('truetype');
}
@font-face {
	font-family: 'Bebas Neue';
	src: local('Bebas Neue'), url(../fonts/Bebas-Neue-Regular.ttf) format('truetype');
}

@font-face {
	font-family: 'Calibri';
	src: local('Calibri'), url(../fonts/Calibri-Bold.ttf) format('truetype');
}

@font-face {
	font-family: 'Comic Sans MS';
	src: local('Comic Sans MS'), url(../fonts/Comic.ttf) format('truetype');
}

@font-face {
	font-family: 'Directive Four';
	src: local('Directive Four'), url(../fonts/Directive-Four-Condensed-Bold.ttf) format('truetype');
}

@font-face {
	font-family: 'Forte';
	src: local('Forte'), url(../fonts/Forte.ttf) format('truetype');
}

@font-face {
	font-family: 'Gang-of-Three';
	src: local('Gang-of-Three'), url(../fonts/Gang-of-Three.ttf) format('truetype');
}

@font-face {
	font-family: 'Hiragino Mincho Pro';
	src: local('Hiragino Mincho Pro'), url(../fonts/hiragino-mincho-pro-w3.otf) format('opentype');
}

@font-face {
	font-family: 'HoboStd';
	src: local('HoboStd'), url(../fonts/HoboStd.ttf) format('truetype');
}

@font-face {
	font-family: 'Impact';
	src: local('Impact'), url(../fonts/Impact.ttf) format('truetype');
}

@font-face {
	font-family: 'JerseyM54';
	src: local('JerseyM54'), url(../fonts/JerseyM54.ttf) format('truetype');
}

@font-face {
	font-family: 'Karate';
	src: local('Karate'), url(../fonts/Karate.ttf) format('truetype');
}

@font-face {
	font-family: 'Lobster';
	src: local('Lobster'), url(../fonts/Lobster.ttf) format('truetype');
}

@font-face {
	font-family: 'Lucida Handwriting';
	src: local('Lucida Handwriting'), url(../fonts/Lucida-Handwriting-Italic.ttf) format('truetype');
}

@font-face {
	font-family: 'Mistral';
	src: local('Mistral'), url(../fonts/Mistral.ttf) format('truetype');
}

@font-face {
	font-family: 'Norwester';
	src: local('Norwester'), url(../fonts/Norwester-Regular.ttf) format('truetype');
}

@font-face {
	font-family: 'OpenSans';
	src: local('OpenSans'), url(../fonts/Open-Sans-Regular.ttf) format('truetype');
}

@font-face {
	font-family: 'PaybAck';
	src: local('PaybAck'), url(../fonts/PaybAck.ttf) format('truetype');
}

@font-face {
	font-family: 'Philosopher Bold';
	src: local('Philosopher Bold'), url(../fonts/Philosopher-Bold.ttf) format('truetype');
}

@font-face {
	font-family: 'PrnStarAcademy';
	src: local('PornStarAcademy'), url(../fonts/Porn-Star-Academy.ttf) format('truetype');
}

@font-face {
	font-family: 'Primetime';
	src: local('Primetime'), url(../fonts/PRIMETIME-Regular.ttf) format('truetype');
}

@font-face {
	font-family: 'Quartzo';
	src: local('Quartzo'), url(../fonts/QUARTZO-demo-Bold.ttf) format('truetype');
}

@font-face {
	font-family: 'Roboto Condensed';
	src: local('Roboto Condensed'), url(../fonts/Roboto-Condensed-Regular.ttf) format('truetype');
}

@font-face {
	font-family: 'Shanghai';
	src: local('Shanghai'), url(../fonts/Shanghai.ttf) format('truetype');
}

@font-face {
	font-family: 'TeleNeo';
	src: local('TeleNeo'), url(../fonts/TeleNeo.otf) format('opentype');
}

@font-face {
	font-family: 'Interstate';
	src: url(../fonts/interstate.eot?#iefix) format('embedded-opentype'),
	     url(../fonts/interstate.woff) format('woff'),
	     url(../fonts/interstate.ttf) format('truetype'),
	     url(../fonts/interstate.svg#Interstate) format('svg');
}



