//$dagobaBlue: '$dagobaBlue'
//$dagobaBlue: #1D267D
//$dagobaBlue: #4d70b8;
$dagobaBlue: #4269af;
//$dagobaBlue50: rgba(39, 53, 122, 0.7);
$dagobaBlue50: rgba(66, 105, 175, 0.7);

$appBg: #F8FCFF;
$collectionBg: #EEfAFF;
$editBoutiqueBg: #fff8e8;
$editCollectionBg: #ffefcb;
$errorCollectionBg: #ffd1cb;
$urlBg: #EEfAFF;
$editURLBg: #ffefcb;

$helpTipBg: #2c6e4a;
$helpTipBgTransp: rgba(44, 110, 74, 0.6);
$helpTipColor: #FFF;
$helpTipBg2: #569a75;
$helpTipBg3: #1b452e;


:export {
	dagobaBlue: $dagobaBlue;
	dagobaBlue50: $dagobaBlue50;
	collectionBg: $collectionBg;
	editBoutiqueBg: $editBoutiqueBg;
	editCollectionBg: $editCollectionBg;
	errorCollectionBg: $errorCollectionBg;
	helpTipBgTransp: $helpTipBgTransp;
}


@mixin website-design {
	--font: 'Barlow Condensed';
	--font-title: 'Interstate';
	--fs-xxl: clamp(1.8rem, 5vw, 3.3rem); /* Minimum: 2rem, Preferred: 5vw, Maximum: 3.5rem */
	--fs-xl: clamp(1.5rem, 4vw, 3rem);
	--fs-lg: clamp(1.3rem, 3vw, 2.5rem);
	--fs-md: clamp(1.15rem, 2.5vw, 2rem);
	--fs-normal: clamp(1rem, 2vw, 1.5rem);
	--fs-s: clamp(0.9rem, 1.8vw, 1.2rem);
	--fs-xs: clamp(0.75rem, 1.5vw, 1rem);

	--fs-catalog: clamp(0.8rem, 1.8vw, 1rem);

	font-family: var(--font), -apple-system, 'Helvetica Neue', sans-serif;
	font-size: var(--fs-normal);

	h1 {
		font-family: var(--font-title);
		font-size: var(--fs-xl);
		font-weight: 700;
	}

	h2 {
		font-size: var(--fs-lg);
		font-weight: 600;
	}

	h3 {
		font-size: var(--fs-md);
		font-weight: 500;
	}

	h4 {
		font-size: var(--fs-normal);
		font-weight: 500;
	}
}

@mixin admin-design {
	--font: 'Roboto Condensed';
	font-family: 'Roboto Condensed', -apple-system, 'Helvetica Neue', sans-serif;
	font-size: 16px;
}


