@import "variables";

.catalog {
	@include website-design;

	width: 100%;
	background-color: $appBg;
	position: relative;
	z-index: 1;

	.loading-catalog {
		width: 100%;
		padding: 50px;
		text-align: center;
		font-size: 1.5em;
		line-height: 1.5em;
		font-weight: 300;
		color: $dagobaBlue;
	}

	.best-seller {
		fill: $dagobaBlue;
	}

	.search-bar {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
		//padding: 8px;
		padding-top: 8px;
		padding-right: 0px;
		margin-top: -30px;

		.select-style {
			&.active {
				background-color: $dagobaBlue;
				color: white;
			}
		}

		.title-select {
			&.active {
				color: $dagobaBlue;
				font-weight: bold;
			}
		}
		.select-brand {
			//margin-left: 8px;
			//min-width: 100px;
			//max-width: 110px;
			//font-size: 13px;
			font-size: var(--fs-catalog);

			&.active {
				background-color: $dagobaBlue;
				color: white;
			}
		}

		.brand-icons {
			display: none;
		}
	}
}

@media screen and (min-width: 799px) {
	.catalog {
		.search-bar {
			.brand-icons {
				padding-top: 26px;
				display: flex;
				justify-content: end;
				flex-wrap: wrap;
				width: 80px;

				.brand-link {
					flex-basis: 33%;
				}

				.brand-logo {
					width: 32px;
					height: 32px;
					padding: 1px;
					margin-left: 5px;
					margin-bottom: 5px;
					background-color: $dagobaBlue;
					border-radius: 3px;
					text-align: center;

					img {
						height: 90%;
					}
				}

				button.brand-logo {
					height: 33px;
					width: 34px;
				}
			}
		}
	}
}

@media screen and (min-width: 875px) and (max-width: 964px) {
	.catalog {
		.search-bar {
			.select-brand {
				max-width: 110px;
			}

			.brand-icons {
				width: auto;
				padding-top: 10px;

				.brand-link {
					flex-basis: unset;
				}
			}
		}
	}
}

@media screen and (min-width: 965px) {
	.catalog {
		.search-bar {
			.select-brand {
				max-width: 120px;
			}

			.brand-icons {
				width: auto;
				padding-top: 10px;

				.brand-link {
					flex-basis: unset;
				}

				.brand-logo {
					width: 48px;
					height: 48px;
				}

				button.brand-logo {
					height: 49px;
					width: 48px;
				}

			}
		}
	}
}

@media screen and (min-width: 1100px) {
	.catalog {
		.search-bar {
			.select-brand {
				max-width: unset;
			}
		}
	}
}

.brand-icons-popover {
	background-color: $dagobaBlue;
	margin-top: 5px;
}

.categories {
	display: flex;
	flex-direction: column!important;
	flex-wrap: wrap;
	padding-left: 0;
	list-style: none;
	margin-block-start: 0px;

	//overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	justify-content: space-between!important;
	margin-bottom: auto!important;

	.category {
		cursor: pointer;
		//margin-bottom: 10px;
		//padding: 5px;
		padding: clamp(0.1rem, 0.8vw, 0.5rem);
		//width: 64px;
		text-align: center;
		//font-size: 0.9em;
		font-size: var(--fs-catalog);
		font-weight: 300;

		.icon{
			//fill: #176ca1;
			fill: $dagobaBlue;
			&:hover {
				fill: #209cea;
			}
		}

		&.active {
			//background-color: #505050;
			//background-color: #176ca1;
			background-color: $dagobaBlue;
			border-radius: 4px;
			color: white;
			.icon{
				//fill:white;
				fill:#F8FCFF;
				&:hover {
					fill:white;
				}
			}
		}

		&.empty {
			opacity: 0.3;
		}
	}
}

.select-tool {

	// fix the legend fucking up the border of select
	fieldset {
		top: 0;
		legend {
			display: none;
		}
	}
}

.brand-delimiter {
	line-height: 28px !important;
	color: white !important;
	background-color: $dagobaBlue !important;
	text-transform: uppercase;
}

.select-gender {
	.all {
		//width: 80px;
	}
	.tgbut {
		&.active {
			background-color: $dagobaBlue;
			svg {
				color: white;
			}
		}
	}
	.adult {
		font-size: 1.5rem;
	}
	.child {
		font-size: 1.2rem;
	}

	button {
		padding-top: 7px;
		padding-bottom: 4px;
	}
}

.puce {
	cursor: pointer;
	display:inline-block;
	position: relative;
	width: 20px;
	height: 20px;
	margin-right: 1px;
	margin-bottom: 1px;
	border-radius: 25%;
	border: 1px solid #C0C0C0;

	&.large {
		width: 32px;
		height: 32px;
	}

	&.big {
		width: 28px;
		height: 28px;
	}

	&.medium {
		width: 16px;
		height: 16px;
	}

	&.small {
		width: 12px;
		height: 12px;
	}

	&.tiny {
		width: 8px;
		height: 8px;
	}

	&.active {
	 border: 3px solid #000000;
	}

	.remove {
		font-size: 20px;
		line-height: 20px;
		position: absolute;
		top: -3px;
		right: 2px;
		color: white;
		&.white-bg {
			color: black;
		}
	}
}

.color-tag {
	display: flex;
	position: relative;
	cursor: pointer;

	&.small {
		height: 15px;
		font-size: 15px;
	}

	.color-top {
		position: absolute;
		z-index: 1;
		height: 55%;
		width: 100%;
		top:0;
	}
	.color-bottom {
		position: absolute;
		z-index: 1;
		height: 40%;
		width: 100%;
		top:60%;
	}
}

.color-group {

	width: 96px;
	&.primary {
		width: 90px;
	}
	&.secondary {
		width: 70px;
	}

	&.white {
		color: white;
		svg {
			fill: white;
		}
	}
	&.black {
		color: black;
		svg {
			fill: black;
		}
	}

	.MuiSelect-select {
		padding-right: 0 !important;
	}

	svg {
		right: 4px;
	}

	&.secondary {

		.MuiSelect-select {
			padding-left: 6px !important;
		}
		svg {
			right: 0px;
		}
	}

}

.color-groups {
	display: flex;
	flex-direction: column;

	.color-group {
		height: 16px;
		width: 20px;
		margin-bottom: 2px;

		.MuiSelect-select {
			height: 16px;
			padding-right: 0 !important;
			padding-left: 25px;
			padding-top: 0px;
			padding-bottom: 0px;
		}

		svg {
			right: -1px !important;
		}
	}
}

.color-img-templates {
	display: flex;
	flex-direction: column;

	.img-select {
		width: 115px;

		.side {
			font-size: 15px;
		}

		.template-select {
			height: 16px;
			width: 100px;
			margin-left: 5px;
			margin-bottom: 2px;

			.MuiSelect-select {
				height: 16px;
				padding-right: 0 !important;
				padding-left: 5px;
				padding-top: 0px;
				padding-bottom: 1px;
				font-size: 13px;
			}

			svg {
				right: -1px !important;
			}
		}
	}
}