@import "variables";

main {
	@include website-design;
}

.help-icon {
	color: $dagobaBlue !important;
	//width: clamp(1.15rem, 2.5vw, 2rem);
	//height: clamp(1.15rem, 2.5vw, 2rem);
	//svg {
	//}
	cursor: pointer;
}

.no-decoration {
	text-decoration: none;
}

.account-not-confirmed {
	background-color: #be0d00 !important;
	color: white !important;
	text-align: center;

	.MuiSvgIcon-root {
		color: white !important;
	}
}

.session-form {
	input, button {
		font-size: var(--fs-normal) !important;
	}
}

.full-screen {
	@include admin-design;
	//width: 99vw;
	//height: 99vh;
	box-sizing: border-box;

	position: fixed;
	width: 100%;

	.full-screen-content {
		width: 100%;
		//height: 100vh;
		overflow-y: auto;
	}

	.close-full-window {
		position: absolute;
		right: 0;
		top: 0;
		color: inherit;
	}
}

.custom-date-picker {
	input {
		padding: 8px;
		//min-width: 100px;
	}
	.MuiInputAdornment-root {
		//margin-left: 0px;
	}
	.MuiButtonBase-root {
		//margin-right: 0px;
	}
}

.color-picker {
	.summary {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 10px;

		.show-color {
			border: 1px solid #A0A0A0;
			width: 200px;
			height: 30px;
			border-radius: 3px;
		}

		.buttons {
			display: flex;
			justify-content: space-between;
			align-items: center;

			button {
				height: 30px;
				margin-left: 5px;
			}
		}
	}
}

//@media screen and (max-width: 820px) {
//	.full-screen {
//		.close-full-window {
//			position: absolute;
//			right: 10px;
//			top: 10px;
//			color: inherit;
//			svg {
//				font-size: 2em;
//			}
//		}
//	}
//}
.callout {
	display: flex;
	flex-direction: column;
	font-size: 1em;
	gap: 8px;
	padding: 12px;
	margin: 16px 0;
	border: 1px solid;
	border-radius: 3px;

	.title {
		display: flex;
		align-items: flex-start;
		font-size: 1.2em;

		&.center {
			justify-content: center;
		}
	}

	&.success {
		color: hsl(144, 72%, 21%);
		background-color: hsl(144, 72%, 95%);
		border-color: hsl(144, 72%, 87%);
	}

	//color: hsl(31, 98%, 34%);
	//background-color: hsl(34, 100%, 83%);
	//border-color: hsl(30, 85%, 45%);

	&.warning {
		color: hsl(215, 15%, 12%);
		background-color: hsla(48, 100%, 96%, 0.5);
		border-color: hsla(36, 100%, 34%, 0.15);

		&.strong {
			color: black;
			background-color: hsla(50, 90%, 92%, 0.8);
			border-color: orange;
		}
	}
	&.error {
		color: hsl(355, 82%, 17%);
		background-color: hsl(355, 82%, 96%);
		border-color: hsl(355, 66%, 84%);
	}
}

/* #root so the rule supercedes the default from react tooltip */
#root {
	.bouda-tip {

		@include website-design;

		z-index: 100000;
		max-width: 50%;
		background-color: $helpTipBg;
		color: $helpTipColor;
		font-size: var(--fs-lg);
		padding: 50px 50px;
		display: flex;
		flex-direction: column;

		.content {
			margin-top: 20px;
			font-size: var(--fs-s);
			opacity: 0.85;
		}

		.tip-arrow {
			width: 48px;
			height: 48px;
		}

		.close {
			z-index: 1;
			position: absolute;
			top: 0;
			right: 0;
			color: #FFF;
			cursor: pointer;
		}

		.pause, .remove {
			z-index: 1;
			position: absolute;
			text-align: center;
			bottom: 1px;
			left: 1px;
			color: $helpTipColor;
			cursor: pointer;
			border: 1px solid $helpTipBg2;
			border-radius: 3px;
			font-size: var(--fs-xs);
			padding: 3px 15px;
			background-color: $helpTipBg3;
			opacity: 1;
		}

		.remove{
			left: unset;
			right: 1px;
		}
	}
}



