/* these make sure it can work in any text element */
.inline-text {
	display: inline-block;
	min-width: 10px;
  position: relative;
  .error-text {
    position: absolute;
    color: #a23431;
    left: 0;
    bottom: -20px;
  }
}

.inline-text_copy--active,
.inline-text_input--active {
  font: inherit;
  color: inherit;
  text-align: inherit;
  padding: 0;
  background: none;
  border: none;
  //border-bottom: 1px dashed #999999;
  outline: none;

  &.error {
    border: 1px solid #a23431;
    box-shadow: 0 0 2px 2px #a23431;
  }
}

.inline-text_copy--active.editable {
  cursor: pointer;
}

.inline-text_copy--hidden,
.inline-text_input--hidden {
  display: none;
}

.inline-text_input--active {
  border: 1px solid #A0A0A0;
  text-align: left;

  &.error {
    border: 1px solid #a23431;
    box-shadow: 0 0 2px 2px #a23431;
  }
}

.inline-text_copy--active.error {
  border: 1px solid #a23431;
  box-shadow: 0 0 2px 2px #a23431;
}
