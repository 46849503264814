@import "../../styles/variables";
//@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;600&display=swap');

//$darkBlue: #26275F;
//$darkBlue: #26447a;
$darkBlue: $dagobaBlue;
$subtitleColor: #303030;
$subtitleColor2: #5A5A5A;

$bgGradient: linear-gradient(135deg, hsl(213 40% 90% / 1), hsl(213 40% 70%));

.website {

	@include website-design;

	overflow: scroll;
	height: calc(100vh - 72px);
	scroll-snap-type: y proximity;
	.section {
		scroll-snap-align: start;
	}

	.hero {
		height: auto;
		margin-top: 0 !important;
		padding: clamp(1rem, 4vw, 3rem);
		display: flex;
		flex-direction: column;
		gap: 1rem;
		background: linear-gradient(90deg,
			rgba(255, 255, 255, 0.9),
			rgba(255, 255, 255, 0.95)),
			url('../../images/website/hangers.jpg');
		background-size: cover;
		background-position: center;

		.hero-line {
			display: flex;
			justify-content: space-between;
			align-items: stretch;

			&.cta {
				justify-content: center;
			}
		}
		.illustrations {
			display: flex;
			justify-content: end;
		}

		.message {
			color: $darkBlue;
			flex-grow: 1;
			display: flex;
			flex-direction: column;
			justify-content: center;

			&.line-1 {
				//padding-right: 3rem;
				// clamp the padding-right from 1rem to 3rem
				padding-right: clamp(1rem, 4vw, 3rem);

				.target {
					font-size: var(--fs-md);
					font-weight: 500;
					color: $subtitleColor;
				}
				.title {
					font-size: var(--fs-xxl);
					margin-block-start: 0;
					margin-block-end: 0;
				}
			}

			&.line-2 {
				text-align: center;
				justify-items: center;

				.subtitle {
					font-size: var(--fs-md);
					font-weight: 500;
					color: $subtitleColor;
					padding: 0px clamp(0.1rem, 2vw, 3rem);
					margin: 1rem 0;
				}
				.cta-container {
					display: flex;
					justify-content: center;

					.cta {
						padding: clamp(0.3rem, 1.5vw, 1rem);
						//background-color: green;
						//color: white;
						font-size: var(--fs-md);
						//border-radius: 5px;
						//cursor: pointer;
					}
				}
				.learn-more {
					font-size: var(--fs-lg);
					font-weight: 500;
					color: $subtitleColor;
					cursor: pointer;
					&:hover {
						text-decoration: underline;
					}
				}
			}
		}

		.illustration {
			display: flex;
			flex-direction: column;
			justify-content: end;
			&.line2 {
				justify-content: start;
			}

			img {
				border-radius: 0.7rem;
				//max-height: 16rem;
				//max-height: clamp(128px, 10vw, 256px);
				max-height: clamp(128px, calc(192px + 64 * ((100vw - 768px) / (1024 - 768))), 256px);

				background: $bgGradient;
				border: 1px solid #C0C0C0;
				margin-left: 1rem;
			}
		}
	}

	.samples {
		display: flex;
		flex-direction: column;
		justify-content: center;
		background: $bgGradient;
		padding: clamp(1rem, 4vw, 2.2rem);
		.title {
			text-align: center;
			h1 {
				font-size: var(--fs-xxl);
				color: $darkBlue;
			}
			margin-bottom: 20px;
		}
		.logos {
			display: flex;
			align-items: center;
			justify-content: space-around;
		}
		.testimonials {
			//padding: 50px 50px 10px 50px;
			padding: clamp(1rem, 4vw, 3rem);
			position: relative;

			.testimonial-container {
				display: flex;
				height: 100%;
				box-sizing: border-box;
				padding: 1rem;

				.testimonial-card {
					display: flex;
					flex-direction: column;
					gap: clamp(0.25rem, 1.2vw, 2rem);
					padding: clamp(0.25rem, 1.2vw, 2rem);
					border: 1px solid #ccc;
					border-radius: 5px;
					background-color: #FAFAFA;

					.testimonial-content {
						display: flex;
						justify-content: space-between;

						.testimonial-logo {
							display: flex;
							align-items: center;
							justify-content: center;
							flex-shrink: 0;
							margin-right: 1rem;

							img {
								//width: 100px;
								//height: 100px;

								width: clamp(48px, calc(48px + 80 * ((100vw - 600px) / (1200 - 600))), 128px);
								height: clamp(48px, calc(48px + 80 * ((100vw - 600px) / (1200 - 600))), 128px);

								object-fit: contain;
							}
						}

						.testimonial-text {
							display: flex;
							flex-grow: 1;
							font-family: 'Barlow Condensed', sans-serif;
							font-size: var(--fs-md);
							font-weight: 500;
							line-height: clamp(1.15rem, 2.5vw, 2rem);
						}
					}

					.testimonial-signature {
						display: flex;
						flex-direction: column;
						align-items: flex-end;
						.name {
							font-size: var(--fs-normal);
							font-weight: bold;
						}
						.role {
							font-size: var(--fs-normal);
						}
						.organization {
							font-size: var(--fs-md);
							font-weight: bold;
							color: $darkBlue;
						}
					}
				}
			}
		}
	}

	.how-it-works {
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: clamp(1rem, 4vw, 3rem);

		background: linear-gradient(135deg,
			rgba(255, 255, 255, 1),
			rgba(255, 255, 255, 0.50)),
			url('../../images/website/Textile-Dagoba.jpg');
		background-size: cover;
		background-position: center;

		.title {
			text-align: center;
			h1 {
				font-size: var(--fs-xxl);
				color: $darkBlue;
			}
		}
		.two-panels {
			.content {
				display: flex;
				flex-direction: column;
				align-items: center;
				padding: clamp(0.25rem, 1.2vw, 2rem);
				opacity: 0.95;

				.panel-content {
					background-color: #eef3f7;
					color: #606060;
					&.us {
						color: $darkBlue;
					}
					border: 1px solid #C0C0C0;
					border-radius: 5px;
					padding: clamp(0.25rem, 1.2vw, 2rem);
					display: flex;
					flex-direction: column;
					gap: clamp(1rem, 2.5vw, 2.5rem);

					.step {
						//min-height: 10rem;
						min-height: clamp(10rem, 20vw, 14rem);
						display: flex;
						flex-direction: column;
						gap: 10px;

						.step-title {
							display: flex;
							align-items: center;
							font-size: var(--fs-lg);
							font-weight: 700;

							svg {
								margin-right: 8px;
							}

							&.tshirt svg {
								path {
									fill: $subtitleColor2;
								}
								margin-left: 4px;
							}
						}
						.step-content {
							font-size: var(--fs-md);
							font-weight: 300;
							color: $subtitleColor;

							.cta2 {
								display: inline-flex;
								margin-left: clamp(0.1rem, 1vw, 0.2rem);
								padding-top: clamp(0px, 0.05vw, 0.3rem);
								padding-bottom: clamp(0.05px, 0.3vw, 0.3rem);
								padding-left: clamp(0.2rem, 1vw, 0.4rem);
								padding-right: clamp(0.2rem, 1vw, 0.4rem);
							}
						}
					}
				}
			}
		}
	}

	.benefits {
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: clamp(1rem, 4vw, 3rem);
		//background: $bgGradient;
		background: linear-gradient(135deg,
			rgba(66, 105, 175, 0.6),
			rgba(66, 105, 175, 0.95)), url('../../images/website/Bluebg.png');
		background-size: cover;
		background-position: left;
		gap: clamp(1rem, 2.5vw, 2rem);

		.title {
			text-align: center;
			h1 {
				font-size: var(--fs-xxl);
				color: white;
			}
		}
		.cta-container {
			display: flex;
			justify-content: center;
			.cta {
				padding: clamp(0.3rem, 1.5vw, 1rem);
				background-color: green;
				color: white;
				font-size: var(--fs-lg);
				border-radius: 5px;
				cursor: pointer;
			}
		}
		.three-panels {
			.content {
				display: flex;
				flex-direction: column;
				align-items: center;
				gap: clamp(1rem, 2.5vw, 2rem);
				padding: clamp(0.25rem, 1.2vw, 2rem);

				.icon {
					svg {
						//font-size: 6em;
						font-size: clamp(4rem, 12vw, 8rem);
						color: white;
					}
				}

				.panel-content {
					color: $darkBlue;
					background-color: #FAFAFA;
					border: 1px solid #C0C0C0;
					border-radius: 5px;
					padding: 10px;
					display: flex;
					flex-direction: column;
					min-height: clamp(15rem, 31vw, 28rem);
					gap: 1rem;

					.panel-title {
						display: flex;
						align-items: center;
						justify-content: center;
						font-size: var(--fs-lg);
						font-weight: 700;
					}

					.panel-text {
						//min-height: 120px;
						display: flex;
						font-size: var(--fs-md);
						font-weight: 300;
						color: $subtitleColor;
					}
				}
			}
		}
	}

	.review {
		padding: clamp(1rem, 4vw, 3rem);

		h1 {
			color: $subtitleColor;
			margin-bottom: clamp(1rem, 4vw, 3rem);
		}

		h2 {
			text-align: center;
			color: $darkBlue;
		}

		h3 {
			text-align: center;
			color: #808080;
		}
		p {
			text-align: center;
			color: #808080;

			a {
				color: #808080;
				text-decoration: none;

				&:hover {
					text-decoration: underline;
				}
			}
		}
	}

	.tos {
		padding: clamp(1rem, 4vw, 3rem);

		h1 {
			text-align: center;
			color: $darkBlue;
		}

		h2 {
			color: $subtitleColor;
		}
	}

	.help-center {
		padding: clamp(1rem, 4vw, 3rem);

		h1 {
			text-align: center;
			color: $darkBlue;

			&.title {
				font-size: var(--fs-xxl);
				margin-top: 0;
			}
		}

		h2 {
			font-size: var(--fs-xl);
			font-weight: bold;
			color: green;
		}

		h3 {
			font-size: var(--fs-lg);
			font-weight: bold;
			color: darkred;
		}

		h4 {
			font-size: var(--fs-md);
			font-weight: bold;
			color: #8227ba;
		}

		a {
			color: $darkBlue;
			font-weight: bold;
			text-decoration: none;
		}
	}

	.footer {
		padding: clamp(2rem, 5vw, 5rem) clamp(1rem, 5vw, 4rem);
		background-color: #000;

		.content {
			display: flex;
			flex-direction: column;
			align-items: start;
			gap: 5px;
			color: hsl(213 40% 70%);

			&.second {
				//padding-left: 30px;
				padding-left: clamp(0px, 1.5vw, 2rem);
			}

			&.third {
				//padding-left: 20px;
				padding-left: clamp(0px, 1.5vw, 2rem);
				//margin-left: clamp(-20px, 1.5vw, 0px);
				margin-left: -20px;
				flex-grow: 1;
			}

			&.follow {
				align-items: end;
			}

			.content-title {
				font-size: var(--fs-md);
				font-weight: 400;
				text-transform: uppercase;
				margin-top: clamp(3rem, 1.5vw, 5rem);
				margin-bottom: 1rem;

				.logo {
					width: clamp(96px, calc(96px + 96 * ((100vw - 600px) / (1200 - 600))), 192px);
					margin-top: -30%;
					margin-left: -10px;
				}

				&.logo {
					margin-bottom: 0.5rem;
					cursor: pointer;
				}
			}

			.content-item {
				font-size: var(--fs-normal);
				line-height: var(--fs-normal);
				font-weight: 300;
				color: #A0A0A0;
				&.sas {
					font-size: var(--fs-s);
					color: hsl(213 40% 70%);
					//margin-top: 5px;
				}
				&.link, a, .link {
					cursor: pointer;
					text-decoration: none;
					color: #A0A0A0;
					&:hover {
						color: white;
					}
				}
			}
		}
	}
}

@media (min-width: 600px) and (max-width: 660px) {
	.website {
		.hero {
			.illustration {
				&.line2.boy {
					display: none;
				}
			}
		}
	}
}

/* Small Devices (Phones - Landscape) */
@media (max-width: 599px) {

	.website {
		.hero {
			.illustration {
				&.line2.boy {
					display: none;
				}
			}
		}

		.how-it-works {
			.two-panels {
				.content {
					display: flex;
					flex-direction: column;
					align-items: center;
					//padding: clamp(0.25rem, 1.2vw, 2rem);
					padding: 1rem;
					opacity: 0.95;

					.panel-content {
						font-size: var(--fs-xl);
						padding: 1rem ;
						gap: 2rem;

						.step {
							min-height: 1rem;
							display: flex;
							flex-direction: column;
							gap: 10px;

							.step-title {
								display: flex;
								align-items: center;
								font-weight: 700;

								svg {
									margin-right: 8px;
								}
							}
							.step-content {
								font-weight: 300;
								font-size: var(--fs-lg);
								color: $subtitleColor;
							}
						}
					}
				}
			}
		}

		.benefits {
			.three-panels {
				.content {
					.panel-content {
						min-height: 1rem;
					}
				}
			}
		}

		.footer {
			padding: 1rem;
			background-color: #000;

			.content {
				display: flex;
				flex-direction: column;
				align-items: center;
				gap: 5px;
				color: hsl(213 40% 70%);

				&.second {
					padding-left: 0;
				}

				&.third {
					padding-left: 0;
					margin-left: 0;
				}

				&.follow {
					align-items: center;
				}

				.content-title {
					font-size: var(--fs-xxl);
					margin-top: 3rem;
					margin-bottom: 1rem;

					.logo {
						width: 192px;
						margin-top: 0.1rem;
						margin-left: 0;
						margin-bottom: 1rem;
					}

				}

				.content-item {
					font-size: var(--fs-xl);
					line-height: var(--fs-xl);
					&.sas {
						font-size: var(--fs-md);
						color: hsl(213 40% 70%);
						margin-top: 5px;
					}
				}
			}
		}
	}
}

/* Very small Devices */
@media (max-width: 399px) {
	.website {
		.hero {
			.hero-line {
				.cta-container.big {
					display: none;
				}
			}
		}
	}
}

/* Very small Devices */
@media (min-width: 400px) {
	.website {
		.hero {
			.hero-line.cta {
				display: none;
			}
		}
	}
}

