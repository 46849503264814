@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

	height: 100vh;
}

code {
  //font-family: "Barlow Condensed", "Roboto Condensed", source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  font-family: "Roboto Condensed", source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@media (max-width: 500px) {
	.hide-xs {
		display: none;
	}
}

.save-actions {
	position: absolute;
	top: 10px;
	right: 10px;

	button {
		margin-left: 5px;
	}
}

.select-lang {
	.MuiSelect-select {
		padding-right: 0 !important;
		padding-top: 0 !important;
	}
	svg {
		fill: white;
		display: none;
	}
}
