@import "../../../styles/variables.scss";

.loading-production {
	width: 100%;
	height: 100%;
	display: flex;
	min-height: 300px;
	flex-direction: column !important;
	align-items: center;
	justify-content: space-around;
	font-size: 1.7em;
	font-weight: 300;
	color: $dagobaBlue;
}

.refresh {
	@include admin-design;
	button {
		font-size: 1.875rem;
		margin-bottom: -24px;
		margin-right: 8px;
		padding: 0px;
	}
}

.read-qr-code {
	@include admin-design;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-top: 10px;
	position: relative;

	.camera-switch {
		position: absolute;
		left: 10px;
		top: -10px;
	}

	.title {
	  font-size: 1.6em;
	  font-weight: 400;
	  margin-bottom: 0px;
	  padding: 10px 30px;
	  text-align: center;
	}

	.scan-video {
	  display: flex;
	  justify-content: center;
	}
	.feedback {
	  color: red;
	  font-size: 1.2em;
	  font-weight: 400;
	  margin-top: 0px;
	  padding: 20px 0px;
	  text-align: center;
	}
}

.production-admin {
	@include admin-design;

	position: relative;
	display: flex;
	flex-direction: column;
	//padding-right: 20px;

	.production-filter {
		display: flex;
		justify-content: space-between;
		align-items: center;
		//padding-left: 5px;
		margin-bottom: 15px;

		.production-view-selector {
			height: 48px;
			min-width: 120px;
			margin-left: 10px;
			margin-top: 9px;
			margin-bottom: -10px;
		}

		.export-button {
			margin-right: 20px;
			margin-top: 20px;
			svg {
				margin-right: 5px;
			}
		}
	}

	.stocks {
		.stock-select {
			display: flex;
			margin-left: 10px;
		}
		.stock-items {
			padding: 5px 10px 10px 10px;
			width: 100%;

			.stock-item-container {
				position: relative;
			}

			.stock-item {
				position: relative;
				display: flex;
				border: 1px solid #CBCBCB;
				border-radius: 5px;

				.delete {
					display: none;
					position: absolute;
					right: -8px;
					top: -8px;
					color: #e73131;
					z-index: 2;
				}

				&:hover {
					.delete {
						display: block;
					}
				}

				.infos {
					flex-grow: 1;
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					padding: 5px;
					overflow: hidden;
					cursor: pointer;
					position: relative;

					.title {
						font-size: 20px;
						font-weight: 600;
						text-wrap: wrap;
						.qty {
							color: red;
						}
					}
					.size {
						font-size: 20px;
						font-weight: 400;
					}
					.color {
						font-size: 15px;
						font-weight: 400;
					}
					.brand {
						font-size: 15px;
						font-weight: 400;
						overflow: hidden;
					}
					.sku {
						font-size: 12px;
						font-weight: 300;
						overflow: hidden;
					}

					.stock-item-box-name {
						position: absolute;
						top: 0px;
						left: 0;
						width: 100%;
						height: 100%;
						display: flex;
						align-items: center;
						justify-content: center;
						font-size: 4.5em;
						font-weight: 600;
						color: black;
						opacity: 0.1;
						z-index: 1;
						text-transform: uppercase;
					}

				}
				.photo {
					display: flex;
					flex-direction: column;
					justify-content: space-between;

					img {
						width: 120px;
					}
					.order-and-box {
						font-size: 1.1em;
						text-align: center;
					}
				}

				.qr-code {
					display: none;
				}
			}
		}

	}

	.production-deliveries {
		display: flex;
		width: 100%;

		.deliveries {
			display: flex;
			flex-direction: column !important;
			justify-content: flex-start;
			align-items: center;
			//flex-wrap: wrap;
			padding: 0px 20px;
			//margin: -5px 0px 0px 10px;
			margin: 0;

			//overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow-x: hidden;

			.delivery {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				cursor: pointer;
				//margin-bottom: 10px;
				padding: 4px;
				width: 120px;
				height: 72px;
				text-align: center;
				font-size: 2em;
				font-weight: 700;
				margin-bottom: 5px;
				border-radius: 3px;
				background-color: #F0F0F0;
				border: 1px solid #CBCBCB;

				.supplier {
					font-size: 16px;
					font-weight: 400;
					overflow: hidden;
				}

				.date {
					font-size: 12px;
					font-weight: 300;
					line-height: 15px;
					overflow: hidden;
				}

				.procure-order {
					font-size: 15px;
					font-weight: 400;
					line-height: 15px;
					overflow: hidden;
				}

				&.with-order {
					height: 104px;
				}

				&.active {
					//background-color: #505050;
					//background-color: #176ca1;
					border-radius: 4px;
					color: white;
					.icon{
						//fill:white;
						fill:#F8FCFF;
						&:hover {
							fill:white;
						}
					}
				}

			}
		}

		.delivery-title {
			display: flex;
			justify-content: space-between;
			align-items: center;
			border: 1px solid #CBCBCB;
			margin: -5px 0px 0px 0px;
			padding: 5px 10px;

			.procure-date {
				font-size: 1em;
				color: #999;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;

				.set-delivered {
					cursor: pointer;
					&:hover {
						color: $dagobaBlue;
					}
				}
			}
			.supplier {
				font-size: 2em;
				font-weight: 600;
			}
			.procure-status {
				display: flex;
				align-items: center;
				flex-direction: column;
				.status {
					font-size: 1.2em;
					font-weight: 600;
				}
				.delivery-date {
					font-size: 1em;
					color: #999;
				}
			}
		}

		.delivery-items {
			padding: 5px 10px 10px 10px;
			width: 100%;

			.delivery-item-container {
				position: relative;

				.delivery-item-status {
					position: absolute;
					top: 0px;
					left: 0px;
					width: 100%;
					height: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 2.2em;
					font-weight: 600;
					opacity: 0.3;
					z-index: 1;
					text-transform: uppercase;
				}
			}

			.delivery-item {
				position: relative;
				display: flex;
				border: 1px solid #CBCBCB;
				border-radius: 5px;

				.save-progress {
					position: absolute;
					left: 50%;
					top: 50%;
					width: 30px;
					height: 30px;
					margin-left: -15px;
					margin-top: -15px;
				}

				.infos {
					flex-grow: 1;
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					padding: 5px;
					overflow: hidden;
					cursor: pointer;

					.title {
						font-size: 20px;
						font-weight: 600;
						.qty {
							color: red;
						}
					}
					.size {
						font-size: 20px;
						font-weight: 400;
					}
					.color {
						font-size: 15px;
						font-weight: 400;
					}
					.brand {
						font-size: 15px;
						font-weight: 400;
						overflow: hidden;
					}
					.sku {
						font-size: 12px;
						font-weight: 300;
						overflow: hidden;
					}
				}
				.photo {
					display: flex;
					flex-direction: column;
					justify-content: space-between;

					img {
						width: 120px;
					}
					.order-and-box {
						font-size: 1.1em;
						text-align: center;
					}
				}

				.qr-code {
					display: none;
				}
			}
		}
	}

	.workshop-boxes {
		padding: 10px;

		height: calc(100vh - 175px);
		overflow-y: auto;

		.workshop-box {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			border: 1px solid #CBCBCB;
			border-radius: 5px;
			min-height: 100px;
			padding: 5px;
			cursor: pointer;

			&.used {
				background-color: #A4DEFB;   // IN_BOX_COLOR of productionSlice
				&.priority {
					background-color: #0080C0;   // PRIORITY_COLOR of productionSlice
					color:white;
				}

				&.complete {
					background-color: #A4FB9C;   // COMPLETE_COLOR of productionSlice

					&.priority {
						background-color: #066f00;   // PRIORITY_COLOR of productionSlice
						color:white;
					}
				}
			}


			.box-header {
				font-size: 2em;
				display: flex;
				justify-content: space-between;
				.box-name {
					font-weight: 600;
				}
				.box-order {
					display: flex;
					font-weight: 600;
					cursor: pointer;

					.nb-items {
						margin-right: 5px;
						font-size: 0.7em;
						font-weight: normal;
						display: flex;
						align-items: center;

						.missing-items {
							color: red;
						}
					}
				}
			}

			.box-items {
				display: flex;
				justify-content: center;
				flex-wrap: wrap;
				gap: 0px 5px;
				.photo {
					img {
						width: 54px;
					}
				}
			}

			.box-qr-code {
				display: none;
			}
		}
	}

	.production-markings {

		display: flex;
		flex-direction: column;
		padding: 0px;
		margin-right: 10px;
		height: calc(100vh - 160px);
		overflow-y: auto;
		width: 100%;

		.order-cell {
			padding: 8px 5px !important;
			line-height: 1;
			background-color: #707070;
			color: white;
			//border-right: 1px solid red;

			&.order-number {
				font-size: 2em;
				font-weight: bold;
			}

			&.btque {
				font-size: 1.5em;
				text-transform: uppercase;
			}

			&.workshop-box {

				.box-name {
					background-color: black;
					color: white;
					font-size: 1.5em;
					font-weight: 600;
					padding: 6px 0px;
					border-radius: 3px;
					width: 50px;
					text-align: center;
				}
			}

			&.quantity {
				.qty {
					display: flex;
					align-items: center;
					justify-content: center;
					background-color: #A0A0A0;
					color: white;
					font-size: 1.2em;
					font-weight: 600;
					border: 1px solid black;
					width: 26px;
					height: 26px;

					&.done {
						background-color: #bbffbb;
						color: black;
						border: 1px solid #4a644a;
					}
				}
			}
		}

		.marking-cell {
			padding: 4px !important;
			line-height: 1;
			//border-right: 1px solid red;
			&.open {
				background-color: #E0E0E0;
			}

			&.qty {
				.box {
					display: flex;
					align-items: center;
					justify-content: center;
					background-color: #A0A0A0;
					color: white;
					font-size: 1.2em;
					font-weight: 600;
					border: 1px solid black;
					width: 26px;
					height: 26px;

					&.done {
						background-color: #bbffbb;
						color: black;
						border: 1px solid #4a644a;
					}

					&.multiple {
						background-color: #303030;

						&.done {
							background-color: #bbffbb;
							color: black;
							border: 1px solid #4a644a;
						}
					}
				}
			}

			&.name {
				font-size: 1.2em;
				font-weight: 400;

				.text-value {
					font-size: 1.1em;
					font-weight: 700;
				}
			}

			&.image {
				.img-container {
					display: flex;
					justify-content: center;
					align-items: center;
					height: 100%;
					width: 100%;
					overflow: hidden;
					background-color: #C0C0C0;
					img {
						height: 32px;
					}

					&.text {
						background: linear-gradient(to bottom right, #F0F0F0, #202020);
					}
				}

			}

			&.action {
				.save-marking {
					margin-left: 5px;
				}
			}
			.print-infos {
				display: flex;
				align-items: center;

				a {
					justify-content: center;
					align-items: center;
					display: flex;
					color: #27357A;
					text-decoration: none;
				}
			}

			.perso-infos {
				display: flex;
				align-content: center;
			}
		}

		.marking-article {
			display: flex;
			border: 2px solid #D0D0D0;
			margin-bottom: 5px;
			margin-left: 70px;
			border-radius: 4px;
			padding: 5px;
			background-color: #F0F0F0;

			.marking-infos {
				flex-basis: 30%;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				.marking-qty {
					font-size: 2.5em;
					font-weight: 700;
				}
				.marking-title {
					font-size: 1.5em;
					font-weight: 600;

					.ref {
						font-size: 0.8em;
						font-weight: 300;
					}
				}
				.marking-boutique {
					font-size: 1.2em;
					font-weight: 400;
					text-transform: uppercase;
				}
			}

			.marking-logo {
				flex-basis: 30%;
				max-width: 220px;
				display: flex;
				flex-direction: column;
				justify-content: end;

				.description {
					padding: 5px;
					background-color: #707070;
					color: white;
					font-size: 0.9em;
					border-bottom: 1px solid #CBCBCB;
					text-align: center;
				}
				.logo-container {
					height: 200px;
					display: flex;
					justify-content: center;
					align-items: center;
					padding: 10px;

					img {
						max-height: 180px;
						max-width: 180px;
					}
				}
			}

			.marking-variant {
				flex-basis: 40%;
				display: flex;
				flex-direction: column;
				.place {
					text-align: center;
					font-size: 1.5em;
					font-weight: bold;
				}
				.preview {
					display: flex;
					justify-content: center;
					align-items: center;
					img {
						max-height: 240px;
					}
				}

			}
		}

		.perso-font {
			margin-left: 10px;
			display: flex;
			align-content: center;
			align-items: center;

			a {
				justify-content: center;
				align-items: center;
				display: flex;
				color: #27357A;
				text-decoration: none;
			}

		}

	}

	.production-marking-series {
		display: flex;
		flex-direction: column;
		//width: 100%;
		.MuiDataGrid-toolbarContainer {
			justify-content: right;

			.MuiButton-root {
				margin-right: 25px;
			}
		}
		.csv-data-grid {
			.MuiDataGrid-main {
				display: none;
			}
		}
		.MuiDataGrid-footerContainer {
			display: none;
		}
	}

	.production-orders {
		display: flex;
		width: 100%;

		.statuses {
			display: flex;
			flex-direction: column !important;
			justify-content: flex-start;
			align-items: center;
			flex-wrap: wrap;
			padding-left: 0;
			margin: -5px 0px 0px 10px;

			//overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;


			.status {
				cursor: pointer;
				//margin-bottom: 10px;
				padding: 5px;
				width: 64px;
				height: 48px;
				text-align: center;
				font-size: 2em;
				font-weight: 700;
				margin-bottom: 2px;
				border-radius: 3px;

				.title {
					font-size: 12px;
					font-weight: 300;
					overflow: hidden;
				}

				&.active {
					//background-color: #505050;
					//background-color: #176ca1;
					border-radius: 4px;
					color: white;
					.icon{
						//fill:white;
						fill:#F8FCFF;
						&:hover {
							fill:white;
						}
					}
				}

				&.empty {
					opacity: 0.3;
				}
			}
		}

		.integration {
			display: flex;
			align-content: center;
			justify-content: center;
		}
	}

}

.stock-item-details {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 15px;
	padding-top: 50px;
	overflow-y: scroll;
	height: calc(100vh - 60px);

	.title {
		font-size: 25px;
		text-align: center;
	}

	.color-size, .brand, .sku {
		font-size: 20px;
		text-transform: uppercase;
	}

	.photo {
		img {
			max-height: 200px;
		}
	}

	.stock-box-name {
		text-align: center;
		width: 80%;
		padding: 10px 25px;
		border-radius: 3px;
		background-color: #e4ffe4;
		font-size: 1.5em;
	}

	.choose-box-button {
		padding: 10px 25px;
		border: 1px solid #CBCBCB;
		border-radius: 3px;
		background-color: #e5e5e5;
		font-size: 1.5em;
		cursor: pointer;
	}

	.choose-order-button {
		padding: 10px 25px;
		border: 1px solid #41a93e;
		border-radius: 3px;
		background-color: #cbffcb;
		font-size: 1.5em;
		cursor: pointer;
	}
}


.box-details {
	display: flex;
	flex-direction: column;

	.box-header {
		padding: 40px 10px 10px;
		font-size: 3em;
		display: flex;
		justify-content: space-between;
		align-items: center;
		.box-name {
			font-weight: 600;
		}
		.box-count {
			display: flex;
			align-items: center;

			.missing-items {
				color: red;
			}
		}
		.box-order {
			font-weight: 600;
			display: flex;
			cursor: pointer;
		}
	}
	.workshop-box-actions {
		display: flex;
		justify-content: center;
		margin-bottom: 10px;
	}

	.box-items {
		height: calc(100vh - 215px);
		overflow-y: auto;
		padding: 5px;
		display: flex;
		justify-content: center;
		align-content: flex-start;
		flex-wrap: wrap;
		gap: 5px;
		.photo {
			border: 1px solid #349b34;
			background-color: #ddffdd;

			&.missing {
				border: 1px solid #ff2b2b;
				background-color: #FFDDDD;
			}

			padding: 7px 7px 5px 7px;
			cursor: pointer;
			img {
				width: 100px;
			}
		}

		.box-empty {
			font-size: 4em;
		}
	}
}

.production-item {
	border: 3px solid #A0A0A0;
	border-radius: 3px;
	margin-bottom: 10px;

	&.in-tool-tip {
		border: none;
		margin-bottom: 0px;
		font-size: 0.7em;
		width: 800px;
	}

	.production-order {
		display: flex;

		justify-content: space-between;
		align-items: center;
		padding: 5px 10px;
		background-color: #F0F0F0;

		.order-number {
			display: flex;
			font-size: 2em;
			font-weight: 600;

			.order-number-date {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				margin-right: 10px;
				.number {
					padding: 2px 5px;
					border-radius: 3px;
					margin-bottom: 2px;
				}
				.date {
					font-size: 0.5em;
					font-weight: 400;
					color: #000;
				}
			}

			.order-box-name {
				display: flex;
				align-items: center;
				font-size: 0.8em;
				background-color: black;
				color: white;
				border: 1px solid #c1c1c1;
				border-radius: 3px;
				padding: 3px 5px;
				//margin-left: 5px;
				min-width: 35px;
				text-align: center;
				cursor: pointer;
			}

			.nb-items {
				//margin-left: 5px;
				font-size: 0.7em;
				font-weight: normal;
				display: flex;
				align-items: center;

				.missing-items {
					color: red;
				}
			}

			svg.partial {
				height: 48px;
				font-size: 48px;
				fill: #9975C0;
			}
		}

		.order-recipient {
			display: flex;
			flex-direction: column;
			align-items: center;

			.order-address {
				max-width: 500px;
				font-size: 0.8em;
				font-weight: 300;
				margin-bottom: 5px;
				display: flex;
				align-content: center;
				align-items: center;
				svg {
					margin-right: 5px;
					opacity: 0.6;
				}
			}

			.order-boutique {
				font-size: 1.3em;
				font-weight: 600;
				//color: $dagobaBlue;
				opacity: 0.6;
				text-transform: uppercase;
			}
		}

		.delivery-on-hold {
			min-width: 150px;
			text-align: center;
			font-weight: bold;
			color: indianred;
			font-size: 1.1em;
		}

		.shipping {
			display: flex;
			flex-direction: column;
			img {
				cursor: pointer;
				height: 24px;
			}
			.shipper {
				display: flex;
				margin-bottom: 3px;
				//align-items: flex-end;

				.logo {
					margin-right: 5px;
				}
				.links {
					display: flex;
					margin-bottom: -4px;
					svg {
						color: #656565;
						height: 20px;
						cursor: pointer;
					}
				}
			}
		}

		&.full-screen {
			position: relative;
			width: 100%;

			.assign-status {
					margin-right: 50px;
			}
		}
	}

	.production-order-items {

		&.full-screen {
			position: relative;
			height: calc(100vh - 69px);
			overflow-y: auto;
		}

		.article {
			border: 2px solid white;

			&.in-tool-tip {
				border: none;
			}

			&.first {
				.article-title {
					border-top: 1px solid white;
				}
			}

			&.expanded {
				border-top: 4px solid #909090;
				border-bottom: 4px solid #909090;
				border-left: 1px solid #909090;
				border-right: 1px solid #909090;
			}

			.article-title {
				display: flex;
				//align-items: center;
				justify-content: space-between;
				font-weight: 400;
				font-size: 1.6em;
				padding: 5px 10px;
				border-top: 1px solid #CBCBCB;

				&.in-tool-tip {
					border-top: none;
				}

				.left {
					display: flex;
					align-items: center;
					justify-content: flex-start;
					flex-grow: 1;

					.qty {
						cursor: pointer;
						width: 32px;
						height: 32px;
						font-size: 1.2em;
						font-weight: 600;
						border: 1px solid #CBCBCB;
						text-align: center;
						margin-right: 5px;
					}

					.title {
						cursor: pointer;
						margin-top: 4px;
						&.checked {
							color: #00CC00;
							font-weight: bold;
						}

						.ref {
							color: #1769c3;
						}
					}

					.embroidery {
						font-size: 1.5em;
						font-weight: 700;
						color: white;
						background-color: darkred;
						width: 40px;
						height: 44px;
						text-align: center;
						border-radius: 3px;
						margin-left: 5px;
					}
				}
				.item-number {
					border: 1px solid #c1c1c1;
					border-radius: 3px;
					padding: 3px 5px;
					margin-right: 5px;
					min-width: 35px;
					text-align: center;
				}
				.workshop-box-name {
					background-color: black;
					color: white;
					border: 1px solid #c1c1c1;
					border-radius: 3px;
					padding: 3px 5px;
					margin-right: 5px;
					min-width: 35px;
					text-align: center;
				}
				.many-deliveries {
					display: flex;
					align-items: center;
					padding: 2px 5px;
					margin-right: 20px;
				}
				.item-procure {
					color: #8f8f8f;
					margin-right: 10px;
					padding: 2px 5px;
					border-radius: 3px;

					&.S10 {          // source DAGOBA STOCK
						background-color: $dagobaBlue;
						color: white;
					}

					&.S20 {          // source ORIFLAM
						background-color: darkorange;
						color: white;
					}

					//&.S30 {          // source external supplier
					//	color: #ffbf2b;
					//}
				}
			}

			.article-details {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 5px 10px;

				.just-photo {
					display: flex;
					justify-content: center;
					width: 100%;
					img {
						max-height: 360px;
					}
				}

				.preview {
					.notes {
						background-color: black;
						color: white;
						padding: 3px;
						border-radius: 3px;
					}
					flex-basis: 65%;
					img {
						max-width: 100%;
						margin-top: -8px;
					}
				}

				.markings {
					flex-basis: 35%;
					display: flex;
					flex-direction: column;

					.marking {
						display: flex;
						flex-direction: column;
						//align-items: center;
						border: 1px solid #CBCBCB;
						margin-top: 5px;
						margin-right: 5px;

						.first-line {
							display: flex;

							.name {
								flex-basis: 50%;
								padding: 5px;
								border-right: 1px solid #CBCBCB;
								display: flex;
								flex-direction: column;
								justify-content: space-around;
								font-size: 1.3em;
								font-weight: bold;

								.technique {
									font-weight: normal;
									color: $dagobaBlue
								}
							}
							.content {
								flex-basis: 50%;
								padding: 3px;
								text-align: center;

								.perso-preview {
									background: linear-gradient(to bottom right, #F0F0F0, #202020);
								}

								.value {
									font-size: 1.35em;
									font-weight: 600;
								}

								.color {
									display: flex;
									align-content: center;
									justify-content: center;

									.color-box {
										width: 16px;
										height: 16px;
										border: 1px solid #CBCBCB;
										margin-right: 5px;
									}
								}
							}
						}

						.second-line {
							text-align: center;
							display: flex;
							flex-direction: column;
							font-size: 1.1em;

							.description {
								padding: 5px;
								background-color: #F0F0F0;
								font-size: 0.9em;
								border-bottom: 1px solid #CBCBCB;
							}

							a {
								justify-content: center;
								align-items: center;
								display: flex;
								color: $dagobaBlue;
								text-decoration: none;
							}
						}
					}

					.logos {
						img {
							max-width: 100px;
						}
					}
				}
			}
			.article-prod-items {
				display: flex;
				column-gap: 10px;
				padding: 10px;

				.prod-item {
					flex-grow: 1;
					border: 1px solid #e5e5e5;
					border-radius: 3px;
					text-align: center;
					font-size: 1.5em;
					padding: 5px 0px;
					cursor: pointer;
					opacity: 0.5;

					&.selected {
						opacity: 1;
						font-weight: bold;
						flex-grow: 2;
						border: 1px solid #404040;
						cursor: unset;
					}

					&.disabled {
						opacity: 0.2;
						border: 1px solid #8f8f8f;
						cursor: unset;
					}

				}
			}
			.article-actions {
				display: flex;
				padding: 10px;
				column-gap: 10px;

				.item-action {
					flex-grow: 2;
					height: 40px;
					cursor: pointer;
					padding: 10px;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 1.3em;

					&.problem {
						flex-grow: 1;
					}
				}
			}
		}
	}

}

// iPhone in vertical mode
@media screen and (max-width: 639px) {

	.production-admin {
		.production-filter {
			.search-boxes {
				display: none;
			}
		}

		.stocks {
			.stock-select {
				display: none;
			}
		}
	}
}

@media screen and (max-width: 820px) {

	.production-admin {
		position: relative;
		display: flex;
		flex-direction: column;

		.production-filter {
			.search-seller, .search-boutique, .search-workshop, .search-start-date, .search-end-date, .select-marking-day, .search-marking  {
				display: none;
			}
		}
	}

	.production-item {
		border: 3px solid #A0A0A0;
		border-radius: 3px;
		margin-bottom: 10px;

		.production-order {
			display: flex;

			justify-content: space-between;
			align-items: center;
			padding: 5px 10px;
			background-color: #F0F0F0;

			.order-number {
				display: flex;
				font-size: 2em;
				font-weight: 600;

				.order-box-name {
					font-size: 0.8em;
					background-color: black;
					color: white;
					border: 1px solid #c1c1c1;
					border-radius: 3px;
					padding: 3px 5px;
					margin-left: 5px;
					min-width: 35px;
					text-align: center;
				}
			}

			.order-recipient {
				display: none;
			}

			.scan {
				margin-right: 8px;
				margin-left: 8px;
			}

			.order-date {
				display: none;
			}

			&.full-screen {
				position: relative;
				width: 100%;

				.order-date {
					margin-top: 20px;
				}

			}
		}

		.production-order-items {

			&.full-screen {
				position: relative;
				height: calc(100vh - 69px);
				overflow-y: auto;
			}

			.article {
				border: 2px solid white;

				&.first {
					.article-title {
						border-top: 1px solid white;
					}
				}

				&.expanded {
					border-top: 4px solid #909090;
					border-bottom: 4px solid #909090;
					border-left: 1px solid #909090;
					border-right: 1px solid #909090;
				}

				.article-title {
					display: flex;
					//align-items: center;
					justify-content: space-between;
					font-weight: 400;
					padding: 5px 10px;
					border-top: 1px solid #CBCBCB;

					.left {
						display: flex;
						align-items: center;
						justify-content: flex-start;
						flex-grow: 1;

						.qty {
							width: 24px;
							height: 24px;
							font-size: 1.2em;
							font-weight: 600;
							border: 1px solid #CBCBCB;
							text-align: center;
							margin-right: 5px;
						}

						.title {
							cursor: pointer;
							margin-top: 4px;

							.ref {
								color: #1769c3;
							}
						}
					}
					.item-number {
						border: 1px solid #c1c1c1;
						border-radius: 3px;
						padding: 3px 5px;
						margin-right: 5px;
						min-width: 35px;
						text-align: center;
					}
					.workshop-box-name {
						background-color: black;
						color: white;
						border: 1px solid #c1c1c1;
						border-radius: 3px;
						padding: 3px 5px;
						margin-right: 5px;
						min-width: 35px;
						text-align: center;
					}
				}

				.article-details {
					display: flex;
					justify-content: space-between;
					align-items: flex-start;
					padding: 5px 10px;

					.preview {
						.notes {
							background-color: black;
							color: white;
							padding: 3px;
							border-radius: 3px;
						}
						flex-basis: 100%;
						img {
							max-width: 100%;
							margin-top: -8px;
						}
					}

					.markings {
						display: none;
					}
				}
				.article-prod-items {
					display: flex;
					column-gap: 10px;
					padding: 10px;

					.prod-item {
						flex-grow: 1;
						border: 1px solid #e5e5e5;
						border-radius: 3px;
						text-align: center;
						font-size: 1.5em;
						padding: 5px 0px;
						cursor: pointer;
						opacity: 0.5;

						&.selected {
							opacity: 1;
							font-weight: bold;
							flex-grow: 2;
							border: 1px solid #404040;
							cursor: unset;
						}

						&.disabled {
							opacity: 0.2;
							border: 1px solid #8f8f8f;
							cursor: unset;
						}

					}
				}
				.article-actions {
					font-size: 0.8em;
					display: flex;
					padding: 10px;
					column-gap: 10px;
					margin-bottom: 40px;    // to avoid the footer which hides the buttons

					.item-action {
						flex-grow: 2;
						height: 40px;
						cursor: pointer;
						padding: 10px;
						display: flex;
						align-items: center;
						justify-content: center;
						font-size: 1.3em;

						&.problem {
							flex-grow: 1;
						}
					}
				}
			}
		}

	}

	.box-details {
		display: flex;
		flex-direction: column;

		.box-header {
			padding: 40px 10px 10px;
			font-size: 3em;
			display: flex;
			justify-content: space-between;
			align-items: center;
			.box-name {
				font-weight: 600;
			}
			.box-count {
				display: flex;
				align-items: center;

				.missing-items {
					color: red;
				}
			}
			.box-order {
				font-weight: 600;
				display: flex;
				cursor: pointer;
			}
		}
		.workshop-box-actions {
			display: flex;
			justify-content: center;
			margin-bottom: 10px;
		}

		.box-items {
			height: calc(100vh - 215px);
			overflow-y: auto;
		}
	}
}


@media print {

	body {
		margin-top: 2mm !important;
		margin-bottom: 3mm !important;

		margin-left: 10px !important;
		margin-right: 10px !important;
	}

	.production-admin {
		.production-filter {
			display: none;
		}

		.workshop-boxes {
			//width: 100%;
			padding: 8px 10px 10px 10px;

			height: unset;
			overflow-y: unset;

			.box-container {
				//padding-top: 15px;
				border-top: 1px solid white;
			}

			.workshop-box {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: space-between;
				//border: 1px solid black;
				border: none;
				min-height: 128px;
				padding: 0px 0px 0px 5px;
				background-color: unset !important;
				height: 278px;

				.box-header {
					.box-name {
						font-size: 90px;
						font-weight: 600;
						margin-left: 30px;
					}
					.box-order{
						display: none;
					}
				}

				.box-items {
					display: none;
				}

				.box-qr-code {
					display: flex;

					img {
						margin-top: 0;
						margin-right: 15px;
						width: 200px;
					}
				}
			}
		}

		.production-deliveries {
			.deliveries {
				display: none;
			}
			.delivery-title {
				display: none;
			}

			.delivery-items {
				padding: 10px 10px 8px 10px;
				width: 100%;

				.delivery-title-container {
					display: none;
				}

				.delivery-item-container {
					padding-top: 14px;
					padding-left: 6px;
					margin-bottom: -12px;
					//border-top: 1px solid black;
					//position: relative;

					.delivery-item-status {
						display: none;
					}
				}

				.delivery-item {
					display: flex;
					border: none;
					border-radius: 5px;
					background-color: unset !important;
					height: 131px;

					.infos {
						flex-grow: 1;
						display: flex;
						flex-direction: column;
						justify-content: space-between;
						padding: 5px 0px 0px 10px;
						overflow: hidden;

						.title {
							font-size: 20px;
							font-weight: 600;
							text-wrap: unset !important;
							.qty {
								color: red;
							}
						}
						.size {
							font-size: 20px;
							font-weight: 400;
						}
						.color {
							font-size: 15px;
							font-weight: 400;
						}
						.brand {
							font-size: 15px;
							font-weight: 400;
						}
						.sku {
							font-size: 15px;
							font-weight: 700;
						}
					}
					.photo {
						position: relative;
						img {
							width: 100px;
							position: absolute;
							right: -15px;
							z-index: -2;
							bottom: 0px;
						}
						.under-photo {
							display: none;
						}
					}

					.qr-code {
						display: flex;
						position: relative;
						flex-direction: column;
						align-items: center;
						//width: 116px;

						img {
							z-index: -1;
							width: 150px;
							margin-top: -17px;
							margin-right: -20px;
						}

						.order {
							text-align: center;
							position: absolute;
							right: 35px;
							bottom: 0px;
						}
					}
				}
			}
		}

		.stocks {
			.stock-select {
				display: none;
			}

			.stock-items {

				.stock-item-container {
					padding-top: 14px;
					padding-left: 6px;
					margin-bottom: -12px;
					//margin-bottom: -10px;
				}

				.stock-item {
					border: none;
					background-color: unset !important;
					height: 131px;

					.delete {
						display: none !important;
					}
					.photo {
						position: relative;

						img {
							width: 100px;
							position: absolute;
							right: -15px;
							z-index: -2;
							bottom: 15px;
						}
					}

					.qr-code {
						display: flex;
						position: relative;
						flex-direction: column;
						align-items: center;
						//width: 116px;

						img {
							z-index: -1;
							width: 150px;
							margin-top: -10px;
							margin-right: -20px;
						}
					}
				}
			}
		}
	}
}

.status-choice:hover, .status-choice.Mui-selected {
	color: black !important;
}
